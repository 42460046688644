<template>
  <div class="realNameInfo">
    <a-card
      class="bindUser"
      v-if="detail && detail.name !== ''"
    >
      <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
        实名认证信息
      </div>
      <p>真实姓名&nbsp;&nbsp;{{ detail.name }}</p>
      <a-row>
        <a-col
          :span="8"
          style="margin-right: 30px"
          v-if="detail.handIdentityCard"
        >
          <viewer
            :images="[
            `${$const.OssImagePrefix}/${detail.handIdentityCard}`
            ]"
          >
            <img
              class="detail-img"
              :src="
              `${$const.OssImagePrefix}/${detail.handIdentityCard}`
              "
            />
          </viewer>
        </a-col>
        <a-col :span="8" style="margin-right: 30px" v-else>
          <img src="@/assets/bk_card.png" />
        </a-col>
        <a-col
          :span="8"
          v-if="detail.frontIdentityCard"
        >
          <viewer
            :images="[
            `${$const.OssImagePrefix}/${detail.frontIdentityCard}`
            ]"
          >
            <img
              class="detail-img"
              :src="
              `${$const.OssImagePrefix}/${detail.frontIdentityCard}`
              "
            />
          </viewer>
        </a-col>
        <a-col :span="8" style="margin-right: 30px" v-else>
          <img src="@/assets/bk_card.png" />
        </a-col>
      </a-row>
      <a-col
          :span="8"
          style="margin-right: 30px;margin-top: 20px;"
          v-if="detail.backIdentityCard"
        >
          <viewer
            :images="[
            `${$const.OssImagePrefix}/${detail.backIdentityCard}`
            ]"
          >
            <img
              class="detail-img"
              :src="
              `${$const.OssImagePrefix}/${detail.handIdentityCard}`
              "
            />
          </viewer>
        </a-col>
        <a-col :span="8" style="margin-right: 30px" v-else>
          <img src="@/assets/bk_card.png" />
        </a-col>
    </a-card>
  </div>
</template>
<script>
export default {
  name: "realNameCertification",
  props: {
    detail: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      queryFilters: {},
    };
  },
  methods: {},
};
</script>
