<template>
  <div class="common-detail-view W100">
    <ak-container type="white" class="header-info-main" card :bodyStyle="{padding: 0}" :loading="loading">
        <UserTop :userInfo="userInfo" />
        <!--  背景图 -->
        <a-card class="create-bk-card">
        <div class="F16 F-BOLD" style="font-weight: bold;">背景图</div>
        <viewer :images="['@/assets/bk_creater.png']">
          <img class="detail-img" src="@/assets/bk_creater.png" />
        </viewer>
      </a-card>

      <!-- 实名认证信息 -->
      <RealNameInfo :detail="realNameCertification"/>

      <!-- <div class="user-info">
        <a-card>
          <div class="F16 F-BOLD" style="font-weight: bold;margin-bottom: 10px;">个人认证信息</div>
          <div class="user-info-content">
            <div class="user-info-certification">简介认证</div>
            <p>6年半品牌设计经验，曾就职于知名大型设计公司和甲方公司。拥有丰富的品牌视觉设计（企业VI系统构建，LOGO、包装、网页、平面广告、画册，营销推广物料）
              实操经验，能把握设计创意与产品商业性的结合。熟悉品牌设计诉求分析，设计策略制定、项目计划制定、策划文案设计配合、设计执行及延展，设计落地的整体实
              现过程。
              具有品牌化思维及策略意识，能根据品牌及产品定位进行视觉设计。注重设计落地的可行性，熟悉印刷制作流程，丰富的工厂对接经验以及有一定的供应商资源。
              对工艺及材质有一定的了解，善于根据画面匹配工艺及材质，把握整体出品。
            </p>
            <div class="user-info-certification">身份认证</div>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">米兰国际设计师，擅长原作/国风</a-col>
            </a-row>
            <div class="user-info-certification">教育经历认证</div>
            <p>2020-2021 某地著名设计学院设计专业毕业</p>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">
                <ak-viewer v-model="fileList" class="fileList"/>
              </a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
            </a-row>

            <div class="user-info-certification">工作经历认证</div>
            <p>2023-2024 大库艺术设计设计师</p>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">
                <ak-viewer v-model="fileList" class="fileList"/>
              </a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
            </a-row>
            <p>2024-2024 逻辑空间设计设计师</p>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">
                <ak-viewer v-model="fileList" class="fileList"/>
              </a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
            </a-row>

            <div class="user-info-certification">获奖认证</div>
            <p>室内设计大奖</p>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">
                <ak-viewer v-model="fileList" class="fileList"/>
              </a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
            </a-row>

            <div class="user-info-certification">展览认证</div>
            <p>2010 龙美术馆，上海</p>
            <a-row :gutter="gutter" class="PB16">
              <a-col :span="6">
                <ak-viewer v-model="fileList" class="fileList"/>
              </a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
              <a-col :span="6"><ak-viewer v-model="fileList" class="fileList"/></a-col>
            </a-row>
          </div>
        </a-card>
      </div> -->
    </ak-container>
  </div>
</template>

<script>
import { queryUserDetail } from '@/api/user/user'
import UserTop from '@/components/UserTop'
import RealNameInfo from '../../components/realNameInfo'

export default {
  components: { UserTop, RealNameInfo },
  data () {
    return {
      gutter: 24,
      loading: false,
      baseInfo: {},
      realNameCertification: {},
      fileList: [{
        fileName: '风景图片.png',
        filePath: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fdesk-fd.zol-img.com.cn%2Ft_s960x600c5%2Fg5%2FM00%2F02%2F05%2FChMkJ1bKyaOIB1YfAAusnvE99Z8AALIQQPgER4AC6y2052.jpg&refer=http%3A%2F%2Fdesk-fd.zol-img.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644897631&t=1d7412373357a29e06b070a4ed53ef71'
      }],
      userInfo: {
        name: "型易事组业构",
        idCardBackUrl: "http://lrku.mil/qreoxtqfpm",
        idCardFrontUrl: "http://bisxmtmn.so/blwd",
        idCardFullUrl: "http://ocspsupdk.ca/pydpfwp"
      },
      userId: ''
    }
  },
  created () {
    this.userId = this.$route.query.id;
    this.queryUserDetail()
  },
  methods: {
    queryUserDetail () {
      this.loading = true
      const param = {
        userId: this.userId
      }
      queryUserDetail(param)
        .then(res => {
          const data = res.data || {}
          console.log("data", data)
          this.userInfo = data.userInfo || {}
          this.personCertification = data.personCertification || {}
          this.realNameCertification = data.realNameCertification || {}
          console.log(this.realNameCertification.handIdentityCard)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
.create-bk-card{
  margin: 20px 0 20px 0;
}
.bk_creater{
  width:100%;
  height: 400px;
  background:  rgb(237, 240, 245) url(../../../assets/bk_creater.png) 0 0 no-repeat;
}
.bussiness{
  margin-top:20px;
}
.fileList .ak-viewer-wrap .preview-thumbnail-list .list-item img{
  width: 300px !important;
  height: 400px !important;
}
</style>>
<style lang="less" scoped>
.common-detail-view {
  .header-info-main {
    padding: 20px;
    .C666 {
      display: inline-block;
      width: 90px;
      text-align: right;
    }
  }
}
.left-detail-card{
  width: 100%
}
.bindUser{
  margin-top: 20px;
}
/deep/ .bindUser .ant-card-body{
  padding: 12px 0 6px 24px;
}
.user-info{
  margin-top: 20px;
  .user-info-content{
    padding: 20px;
    .user-info-certification{
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}
.ant-col{margin-bottom: 8px;}
.ant-col:last-child{margin-bottom: 0;}
// .ant-row{
//   margin-bottom: 8px;
// }
.ant-row:last-child{margin-bottom: 0;}
</style>
