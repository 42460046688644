var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realNameInfo"},[(_vm.detail && _vm.detail.name !== '')?_c('a-card',{staticClass:"bindUser"},[_c('div',{staticClass:"F16 F-BOLD",staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v(" 实名认证信息 ")]),_c('p',[_vm._v("真实姓名  "+_vm._s(_vm.detail.name))]),_c('a-row',[(_vm.detail.handIdentityCard)?_c('a-col',{staticStyle:{"margin-right":"30px"},attrs:{"span":8}},[_c('viewer',{attrs:{"images":[
          `${_vm.$const.OssImagePrefix}/${_vm.detail.handIdentityCard}`
          ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.detail.handIdentityCard}`}})])],1):_c('a-col',{staticStyle:{"margin-right":"30px"},attrs:{"span":8}},[_c('img',{attrs:{"src":require("@/assets/bk_card.png")}})]),(_vm.detail.frontIdentityCard)?_c('a-col',{attrs:{"span":8}},[_c('viewer',{attrs:{"images":[
          `${_vm.$const.OssImagePrefix}/${_vm.detail.frontIdentityCard}`
          ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.detail.frontIdentityCard}`}})])],1):_c('a-col',{staticStyle:{"margin-right":"30px"},attrs:{"span":8}},[_c('img',{attrs:{"src":require("@/assets/bk_card.png")}})])],1),(_vm.detail.backIdentityCard)?_c('a-col',{staticStyle:{"margin-right":"30px","margin-top":"20px"},attrs:{"span":8}},[_c('viewer',{attrs:{"images":[
          `${_vm.$const.OssImagePrefix}/${_vm.detail.backIdentityCard}`
          ]}},[_c('img',{staticClass:"detail-img",attrs:{"src":`${_vm.$const.OssImagePrefix}/${_vm.detail.handIdentityCard}`}})])],1):_c('a-col',{staticStyle:{"margin-right":"30px"},attrs:{"span":8}},[_c('img',{attrs:{"src":require("@/assets/bk_card.png")}})])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }